import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { keycloak } from "./plugins/keycloak";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import { requestPermission } from "./plugins/firebase";
import userStore from "./store/user";
import sessionStore from './plugins/session'
class main {
  async init() {
    Vue.config.productionTip = false;
    await sessionStore.setSession()

    // const { token, refreshToken } = sessionStore.state
    const keycloakInit =  { onLoad: 'login-required' }
        
    await keycloak.setConfig();
    await keycloak.init(keycloakInit);

    Vue.use(keycloak.Plugin);
    Vue.use(CKEditor);
    requestPermission();
    await Vue.$keycloak.loadUserProfile();
    await userStore.saveDeviceId(Vue.$keycloak.profile.id);
    await keycloak.updateTokenIfExpired()
    
    new Vue({
      vuetify,
      router,
      render: h => h(App)
    }).$mount("#app");
  }
}

const app = new main();
app.init();
